import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView";
import IS_CORPORATE from '../config/siteConfig';


const Impressum = ({ data: { content:contentCorporate,contentVentures }, pageContext: { locale }, location }) => {
    const lang = React.useContext(LocaleContext)
    //const i18n = lang.i18n[lang.locale]
    const content = IS_CORPORATE ? contentCorporate : contentVentures;
  
    return (
        <>
            <SEO
                 title={'Impressum'}
                 description={content.data.seo_impressum_beschreibung.text}
                 keywords={content.data.seo_impressum_keywords.text ?content.data.seo_impressum_keywords.text.split(', ') :
                 [`Impressum`]}
            />
        <DetailView img={content.data.impressumbild ? content.data.impressumbild:null}>
            <section id="impressum">
               <div className="container">
                <div
                    dangerouslySetInnerHTML={{
                        __html: content.data.impressum.html,
                    }}
                    /></div>
            </section>
</DetailView>
        </>
    )
}


export default Impressum


Impressum.propTypes = {
    data: PropTypes.shape({
        content: PropTypes.object.isRequired
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
         query ImpressumQuery($locale: String!) {
           content: prismicHomepage(lang: { eq: $locale }) {
             data {
               title {
                 text
               }
               impressum {
                 html
               }
               seo_impressum_beschreibung {
                text
              }
              seo_impressum_keywords {
                text
              }
             }
           },
           contentVentures: prismicHomepageVentures(lang: { eq: $locale }) {
            data {
              title {
                text
              }
              impressum {
                html
              }
              seo_impressum_beschreibung {
               text
             }
             seo_impressum_keywords {
               text
             }
            }
          }
         }
       `

       /*
impressum_bild {
                  alt
                  url
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
       */